@charset "UTF-8";

.footer_box1 {
    padding-top: 80px;
    padding-bottom: 105px;

    .wrap {
        display: flex;
        justify-content: space-between;

        .left {
            max-width: 640px;
            width: 100%;
            margin-right: 20px;

            .parts_title1 {
                padding-bottom: 70px;
            }

            .main {
                text-align: left;

                article {
                    border-bottom: 1px solid #999999;
                    box-sizing: border-box;
                    margin-bottom: 15px;

                    a {
                        display: flex;
                        padding-left: 8px;
                        padding-bottom: 15px;
                        box-sizing: border-box;

                        span {
                            width: 120px;
                            padding-right: 30px;
                            box-sizing: border-box;
                            color: #999999;
                            position: relative;

                            &::after {
                                position: absolute;
                                top: 0;
                                right: 20px;
                                content: "";
                                display: block;
                                width: 1px;
                                height: 20px;
                                background-color: #999999;
                            }
                        }

                        .title {
                            flex: 1;
                            color: #333333;
                        }
                    }
                }
            }
        }

        .right {
            max-width: 350px;
        }
    }

    @include res($resmd) {
        padding-top: 50px;
        padding-bottom: 60px;

        .wrap {
            display: block;

            .left {
                max-width: 1000px;
                margin-right: 0px;
                padding-bottom: 50px;

                .parts_title1 {
                    padding-bottom: 40px;
                }
            }

            .right {
                max-width: 350px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    @include res($res414) {
        .wrap {
            .left {
                .main {
                    article {
                        margin-bottom: 10px;

                        a {
                            display: block;
                            padding-left: 0px;
                            padding-bottom: 10px;

                            span {
                                width: auto;
                                padding-right: 0px;
                                padding-bottom: 6px;
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .right {
                max-width: 280px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.footer {
    background-color: #0f0f65;
    color: #fff;
    padding-top: 56px;

    .wrap {
        display: flex;
        justify-content: space-between;
        padding-bottom: 14px;

        .title {
            width: 230px;

            a {
                display: block;
                max-width: 160px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 20px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            span {
                display: none;
            }

            p {
                display: inline-block;
            }
        }

        .nav {
            display: flex;

            ul {
                text-align: left;

                li {
                    margin-bottom: 22px;

                    a {
                        color: #fff;
                        display: inline-block;
                        position: relative;

                        &:after {
                            display: block;
                            position: absolute;
                            bottom: -2px;
                            left: 0;
                            content: "";
                            width: 0;
                            height: 1px;
                            background-color: #fff;
                            @include trans2(3);
                        }

                        &:hover {
                            &:after {
                                width: 100%;
                                @include trans2(3);
                            }
                        }
                    }
                }

                &:nth-child(1) {
                    margin-right: 80px;
                }

                &:nth-child(2) {
                    margin-right: 60px;
                }
            }
        }
    }

    .bottom {
        border-top: 1px solid #58588a;
        padding-top: 21px;
        padding-bottom: 22px;
        box-sizing: border-box;

        small {
            @include fontsize(11);
            color: #fff;
        }
    }

    @include res($resmd) {
        .wrap {
            display: block;
            padding-bottom: 40px;

            .title {
                width: 100%;

                a {
                    max-width: 200px;
                }

                span {
                    display: block;
                    @include fontsize(20);
                    @include font_m;
                    padding-bottom: 10px;
                }
            }

            .nav {
                display: none;
            }
        }
    }

    @include res($res414) {
        padding-top: 29px;

        .wrap {
            padding-bottom: 26px;

            .title {
                a {
                    max-width: 132px;
                }

                span {
                    @include fontsize(16);
                    padding-bottom: 7px;
                }
            }
        }

        .bottom {
            padding-top: 19px;
            padding-bottom: 18px;
        }
    }

}
