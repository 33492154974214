@charset "UTF-8";

.top_box5_main_anime {
    overflow: hidden;
    article {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.top_box5_main_anime[data-emergence=visible] {
    article {
        &:nth-child(1) {
            animation:
                tranY-10 2s 0.4s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }
        &:nth-child(2) {
            animation:
                tranY-10 2s 0.6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }
        &:nth-child(3) {
            animation:
                tranY-10 2s 0.8s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }
        &:nth-child(4) {
            animation:
                tranY-10 2s 1.0s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }
    }
}

