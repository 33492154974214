@charset "UTF-8";
@import "fonts";

$res1920: 1920;
$res1800: 1800;
$res1700: 1700;
$res1600: 1600;
$res1500: 1500;
$res1550: 1550;
$res1400: 1400;
$res1300: 1300;
$res1200: 1200;
$res1100: 1100;
$res1024: 1024;
$res1000: 1000;
$reslg: 991;
$res900: 900;
$res800: 800;
$res700: 700;
$resmd: 767;
$res600: 600;
$res500: 500;
$res400: 400;
$res414: 414;
$iphone7: 375;
$iphonese: 320;

@mixin fontsize($size: 24, $base: 16) {
    font-size: $size + px;
    font-size: ($size / $base) * 1rem;
}


@mixin lus_b {
    font-family: 'Lusitana', serif;
    font-weight: bold;
}

@mixin font_g {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
    font-weight: 500;
}

@mixin font_m {
    font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;    font-weight: 500;
}


@mixin fontNBL {
    font-family: 'NotoSansCJKjp-Black', sans-serif;
}

@mixin fontNBO {
    font-family: 'NotoSansCJKjp-Bold', sans-serif;
}

@mixin fontNDE {
    font-family: 'NotoSansCJKjp-DemiLight', sans-serif;
}

@mixin fontNME {
    font-family: 'NotoSansCJKjp-Medium', sans-serif;
}

@mixin fontNRE {
    font-family: 'NotoSansCJKjp-Regular', sans-serif;
}

@mixin fontNTH {
    font-family: 'NotoSansCJKjp-Thin', sans-serif;
}

@mixin fontNRI {
    font-family: 'NotoSansCJKjp-Light', sans-serif;
}

@mixin anzu{
  font-family: 'APJapanesefont', sans-serif;
}

@mixin fontPO{
    font-family: 'Poppins', sans-serif;
}

/////////////レスポンシブ/////////////

@mixin res($size) {
    @media only screen and (max-width: $size + "px") {
        @content;
    }
}

@mixin res_min($size) {
    @media only screen and (min-width: $size + "px") {
        @content;
    }
}



@mixin trans1($time: 1) {
    transition: $time * 0.1s cubic-bezier(1, 0, 0, 1);
}

@mixin trans2($time: 1) {
    transition: $time * 0.1s cubic-bezier(0.19, 1, 0.22, 1);
}

@mixin transition01{
    transition:0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}
