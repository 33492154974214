@charset "UTF-8";

.pr_font32_26_22 {
    @include fontsize(32);

    @include res($resmd) {
        @include fontsize(26);
    }
    @include res($res414) {
        @include fontsize(22);
    }
}

.pr_font30_24_20 {
    @include fontsize(30);

    @include res($resmd) {
        @include fontsize(24);
    }
    @include res($res414) {
        @include fontsize(20);
    }
}

.pr_font28_22_18 {
    @include fontsize(28);

    @include res($resmd) {
        @include fontsize(22);
    }
    @include res($res414) {
        @include fontsize(18);
    }
}

.pr_font18_16_14 {
    @include fontsize(18);
    @include res($resmd) {
        @include fontsize(16);
    }
    @include res($res414) {
        @include fontsize(14);
    }
}

.pr_font16__15 {
    @include fontsize(16);

    @include res($res414) {
        @include fontsize(15);
    }
}

.pr_font16__14 {
    @include fontsize(16);

    @include res($res414) {
        @include fontsize(14);
    }
}

.pr_font16__12 {
    @include fontsize(16);

    @include res($res414) {
        @include fontsize(12);
    }
}

.pr_font14__13 {
    @include fontsize(14);

    @include res($res414) {
        @include fontsize(13);
    }
}

.pr_font14__12 {
    @include fontsize(14);

    @include res($res414) {
        @include fontsize(12);
    }
}

.pr_font11 {
    @include fontsize(11);
}
