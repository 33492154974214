@charset "UTF-8";

html {
    font-size: 16px;
    margin: 0 !important;
    padding: 0;
    @include font_g;
    font-weight: 500;
}

body {
    color: #1d1d1d;
    line-height: 1.4;
    text-align: center;
    z-index: 0;
    word-break: break-all;
    z-index: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    // font-smoothing: antialiased;
}

main {
    position: relative;
}

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
brockquote,
pre,
table,
caption,
th,
td,
address,
form,
fieldset,
legend,
object {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

////////////　フォ－ム
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
    @include font_g;
}

li {
    list-style: none;
}

a {
    transition: all 0.1s;
    color: #333;

    &:hover {
        cursor: pointer;
    }
}

a:link,
a:visited,
a:active,
a:hover {
    outline: none;
    transition: all 0.1s;
    color: #333;
    text-decoration: none;
}

a,
ins {
    text-decoration: none;
}

address,
em,
cite,
dfn,
var {
    font-style: normal;
}

input,
textarea {
    font-family: sans-serif;
    font-weight: normal;
}

img {
    vertical-align: bottom;
}

a img {
    border: none;
}
