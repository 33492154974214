@charset "UTF-8";

.top_box2_anime {
    .main {
        .right {
            opacity: 0;
            transform: translateY(20px);
        }

        .left {
            opacity: 0;
            transform: translateY(20px);
        }
    }

    .more {
        opacity: 0;
        transform: translateY(20px);
    }
}

.top_box2_anime[data-emergence=visible] {
    .main {
        .right {
            animation:
                tranY20 3.0s 0.2s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }

        .left {
            animation:
                tranY20 3.0s 0.8s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }
    }

    .more {
        animation:
            tranY20 3.0s 1.6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    }
}
