@charset "UTF-8";
@import "settings/value";
@import "settings/value2";

@import "settings/reset";
@import "settings/parts";
@import "settings/font_size";
@import "layout/header";
@import "layout/footer";
@import "layout/top";

@import "anime/header_top";
@import "anime/top_box2";
@import "anime/top_box3";
@import "anime/top_box4";
@import "anime/top_box5";
@import "anime/keyframes";
