@charset "UTF-8";

.top_box4_main_anime {
    overflow: hidden;
    article {
        div {
            opacity: 0;
            transform: translateY(20px);
        }

        p {
            opacity: 0;
            transform: translateY(20px);
        }
    }
}

.top_box4_main_anime[data-emergence=visible] {
    article {
        &:nth-child(1) {
            div {
                animation:
                    tranY20 2s 0.4s cubic-bezier(0.19, 1, 0.22, 1) forwards;
            }
            p {
                animation:
                    tranY20 2s 0.8s cubic-bezier(0.19, 1, 0.22, 1) forwards;
            }
        }
        &:nth-child(2) {
            div {
                animation:
                    tranY20 2s 1.2s cubic-bezier(0.19, 1, 0.22, 1) forwards;
            }
            p {
                animation:
                    tranY20 2s 1.6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
            }
        }
        &:nth-child(3) {
            div {
                animation:
                    tranY20 2s 2.0s cubic-bezier(0.19, 1, 0.22, 1) forwards;
            }
            p {
                animation:
                    tranY20 2s 2.4s cubic-bezier(0.19, 1, 0.22, 1) forwards;
            }
        }
    }
}
