@charset "UTF-8";

.top_works_wrap_anime {
    .top_works_article {
        opacity: 0;
        &:nth-child(1) {
            transform: translateX(-50px);
        }
        &:nth-child(2) {
            transform: translateX(50px);
        }
        &:nth-child(3) {
            transform: translateX(-50px);
        }
        &:nth-child(4) {
            transform: translateX(50px);
        }
        &:nth-child(5) {
            transform: translateX(-50px);
        }
    }
}

// .top_works_wrap_anime_done {
.top_works_wrap_anime[data-emergence=visible] {
    .top_works_article {

        &:nth-child(1) {
            animation: tranX-50 2.0s 0.2s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }

        &:nth-child(2) {
            animation: tranX50 2.0s 0.8s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }

        &:nth-child(3) {
            animation: tranX-50 2.0s 1.4s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }

        &:nth-child(4) {
            animation: tranX50 2.0s 2.0s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }

        &:nth-child(5) {
            animation: tranX-50 2.0s 2.6s cubic-bezier(0.19, 1, 0.22, 1) forwards;
        }
    }
}

