@charset "UTF-8";

@font-face {
    font-family: 'NotoSansCJKjp-Black';
    /*01*/
    src: url("../f_font/NotoSansCJKjp-Black.woff");
}
@font-face {
    font-family: 'NotoSansCJKjp-Bold';
    /*02*/
    src: url("../f_font/NotoSansCJKjp-Bold.woff");
}
@font-face {
    font-family: 'NotoSansCJKjp-DemiLight';
    /*03*/
    src: url("../f_font/NotoSansCJKjp-DemiLight.woff");
}
@font-face {
     font-family: 'NotoSansCJKjp-Light';
    /*04*/
    src: url("../f_font/NotoSansCJKjp-Light.woff");
}
@font-face {
    font-family: 'NotoSansCJKjp-Medium';
    /*05*/
    src: url("../f_font/NotoSansCJKjp-Medium.woff");
}
@font-face {
    font-family: 'NotoSansCJKjp-Regular';
    /*06*/
    src: url("../f_font/NotoSansCJKjp-Regular.woff");
}
@font-face {
    font-family: 'NotoSansCJKjp-Thin';
    /*07*/
    src: url("../f_font/NotoSansCJKjp-Thin.woff");
}

@font-face {
    font-family: 'APJapanesefont';
    /*07*/
    src: url("../f_font/APJapanesefont.woff");
}
