@charset "UTF-8";

.parts_hover1 {
    @include trans2(3);

    &:hover {
        @include trans2(3);
        opacity: 0.6;
    }
}

.parts_hover2 {
    span {
        @include trans2(3);
    }

    &:hover {
        span {
            @include trans2(3);
            right: -10px;
        }
    }
}

.parts_hover3 {
    span {
        @include trans2(3);
    }

    &:hover {
        span {
            @include trans2(3);
            right: 10px;
        }
    }
}

.parts_hover4 {
    @include trans2(3);

    &:hover {
        @include trans2(3);
        text-decoration: none !important;
    }
}

.parts_hover5 {
    @include trans2(3);
    display: block;

    &:hover {
        @include trans2(3);
        opacity: 0.6;
    }
}

.parts_hover6 {
    position: relative;

    &::before {
        z-index: 1;
        @include trans2(3);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        content: "";
        display: block;
        width: 0;
        height: 0;
        @include trans2(3);
        background-color: RGBA(255, 255, 255, 0.6);
    }
    span {
        @include trans2(3);
        position: relative;
        z-index: 2;
    }

    &:hover {
        &::before {
            width: 100%;
            height: 100%;
            @include trans2(3);
        }
        span {
            @include trans2(3);
            color: rgb(51, 51, 51) !important;
        }
    }
}

.parts_hover7 {
    overflow: hidden;
    &::before {
        @include trans2(6);
    }
    &:hover {
        &::before {
            @include trans2(6);
            transform: scale(1.03);
            opacity: 0.9;
        }
    }
}

.parts_monthlyList {
    li {
        a {
            @include trans2(3);

            &:hover {
                @include trans2(3);
                opacity: 0.6;
            }
        }
    }
}

.parts_maxwidth1030 {
    box-sizing: border-box;
    margin: auto;
    max-width: 1070px;
    padding-right: 20px;
    padding-left: 20px;
}

.parts_title1 {

    &::before {
        display: block;
        content: "";
        width: 1px;
        height: 32px;
        background: #999999;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
    }

    .title {
        color: #333333;
        @include font_m;
        @include fontsize(26);
        font-weight: 500;
        padding-bottom: 8px;
    }

    p {
        color: #999999;
        @include lus_b;
        @include fontsize(14);
        padding-bottom: 15px;
        letter-spacing: 1.3px;
    }

    &::after {
        display: block;
        content: "";
        width: 1px;
        height: 16px;
        margin-left: auto;
        margin-right: auto;
        background: #999999;
    }

    @include res($resmd) {

        .title {
            @include fontsize(24);
        }

        p {
            @include fontsize(13);
        }
    }

    @include res($res414) {
        &::before {
            height: 22px;
            margin-bottom: 12px;
        }

        .title {
            @include fontsize(20);
            padding-bottom: 6px;
        }

        p {
            @include fontsize(11);
            padding-bottom: 11px;
        }

        &::after {
            height: 11px;
        }
    }
}

.parts_more1 {
    &:hover {
        span {
            @include trans2(3);
            right: 20px;
        }
    }

    display: flex;
    max-width: 485px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    align-items: center;
    @include lus_b;
    @include fontsize(14);
    color: #333333;

    span {
        @include trans2(3);
        position: absolute;
        display: block;
        bottom: 28px;
        right: 33px;
        width: 40px;

        img {
            width: 100%;
            height: auto;
        }
    }

    i {
        position: absolute;
        display: block;
        background: #333333;

        &:nth-child(1) {
            width: calc(100% - 8px);
            height: 1px;
            top: 0;
            right: 8px;
        }

        &:nth-child(2) {
            width: 1px;
            height: 100%;
            top: 0;
            left: 0;
        }

        &:nth-child(3) {
            width: calc(100% - 8px);
            height: 1px;
            bottom: 0;
            left: 8px;
        }

        &:nth-child(4) {
            width: 1px;
            height: 100%;
            top: 0;
            right: 0;
        }
    }

    @include res($resmd) {
        max-width: 280px;
        height: 40px;

        &:hover {
            span {
                right: 10px;
            }
        }

        span {
            bottom: 20px;
            right: 20px;
        }
    }
}

.parts_more2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    @include lus_b;
    @include fontsize(13);
    color: #333333;
    border: 1px solid #949491;
    border-radius: 20px;
    position: relative;
    &:after {
        z-index: 1;
        content: "";
        display: block;
        position: absolute;
        top: -2px;
        right: 20px;
        width: 7px;
        height: 4px;
        background-color: #F4F5F0;
    }
    &::before {
        z-index: 1;
        content: "";
        display: block;
        position: absolute;
        bottom: -2px;
        left: 20px;
        width: 7px;
        height: 4px;
        background-color: #F4F5F0;
    }
    span {
        display: inline-block;
        position: absolute;
        top: 7px;
        right: 22px;
        width: 7px;
        img {
            width: 100%;
            height: auto;
        }
    }
}

.part_pagination {
    padding-top: 40px;

    .pagination {
        @include lus_b;
        @include fontsize(20);
        font-weight: 600;
        display: flex;
        justify-content: center;
        padding: 0 0 120px;

        .active {
            margin-left: 8px;
            margin-right: 8px;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border: 1px solid #999999;
                background-color: #999999;
                color: #fff;
                box-sizing: border-box;
            }
        }

        .def {
            margin-left: 8px;
            margin-right: 8px;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border: 1px solid #999999;
                background-color: #fff;
                color: #999999;
                box-sizing: border-box;
                transition: 0.5s;

                &:hover {
                    background-color: #999999;
                    color: #fff;
                    transition: 0.5s;
                }
            }
        }

        .pagenav_left {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 32px;

            a {
                display: inline-block;
                color: #999999;
                position: relative;

                i {
                    font-style: normal;
                }

                &::before {
                    content: "";
                    display: inline-block;
                    width: 7px;
                    height: 11px;
                    background-image: url(../img/common/left.png);
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-size: 7px 11px;
                    margin-right: 10px;
                }

                &::after {
                    position: absolute;
                    bottom: -3px;
                    right: 0;
                    content: "";
                    display: block;
                    width: 0;
                    height: 2px;
                    background-color: #999999;
                    transition: 0.5s;
                }

                &:hover {
                    &::after {
                        width: 80%;
                        transition: 0.5s;
                    }
                }
            }
        }

        .pagenav_right {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 32px;

            a {
                display: inline-block;
                color: #999999;
                position: relative;

                i {
                    font-style: normal;
                }

                &::after {
                    content: "";
                    display: inline-block;
                    width: 7px;
                    height: 11px;
                    background-image: url(../img/common/right.png);
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-size: 7px 11px;
                    margin-left: 10px;
                }

                &::before {
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    content: "";
                    display: block;
                    width: 0;
                    height: 2px;
                    background-color: #999999;
                    transition: 0.5s;
                }

                &:hover {
                    &::before {
                        width: 80%;
                        transition: 0.5s;
                    }
                }
            }
        }
    }

    @include res($resmd) {
        .pagination {
            padding: 0 0 100px;
            @include fontsize(18);

            .active {
                margin-left: 4px;
                margin-right: 4px;

                span {
                    width: 40px;
                    height: 40px;
                }
            }

            .def {
                margin-left: 4px;
                margin-right: 4px;

                a {
                    width: 40px;
                    height: 40px;

                }
            }

            .pagenav_left {
                margin-right: 8px;

                a {
                    width: 20px;

                    i {
                        display: none;
                    }

                    &::before {
                        width: 7px;
                        height: 11px;
                        background-size: 7px 11px;
                        margin-right: 5px;
                    }

                    &::after {
                        bottom: -3px;
                        height: 2px;
                    }

                    &:hover {
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }

            .pagenav_right {
                margin-left: 8px;

                a {
                    width: 20px;

                    i {
                        display: none;
                    }

                    &::after {
                        width: 7px;
                        height: 11px;
                        background-size: 7px 11px;
                        margin-left: 5px;
                    }

                    &::before {
                        bottom: -3px;
                        height: 2px;
                    }

                    &:hover {
                        &::before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}


.parts_news_bg {
    background-image: url("../img/main_title_img/news_bg.jpg") !important;
}

.parts_blog_bg {
    background-image: url("../img/main_title_img/blog_bg.jpg") !important;
}


.parts_concept_bg {
    background-image: url("../img/main_title_img/concept_bg.jpg") !important;
}

.parts_flow_bg {
    background-image: url("../img/main_title_img/flow_bg.jpg") !important;
}

.parts_works_bg {
    background-image: url("../img/main_title_img/works_bg.jpg") !important;
}

.parts_price_bg {
    background-image: url("../img/main_title_img/price_bg.jpg") !important;
}

.parts_company_bg {
    background-image: url("../img/main_title_img/company_bg.jpg") !important;
}

.parts_contact_bg {
    background-image: url("../img/main_title_img/contact_bg.jpg") !important;
}
