@charset "UTF-8";

.top_box1 {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .top_log {
        width: 315px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .top_slider {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        .slide {
            width: 100%;
            height: 100%;
            opacity: 0;
            transform: scale(1);
            transition: opacity 2s linear, transform 7.5s linear;
            transition: opacity 2s linear, transform 7.5s linear,
                -webkit-transform 7.5s linear;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: block;
                background: rgba(51, 51, 51, 0.5);
            }

            &:not(:first-child) {
                position: absolute;
                top: 0;
                left: 0;
            }

            span {
                display: block;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50%;
            }
        }

        .show_ {
            opacity: 1;
        }

        .zoom_ {
            transform: scale(1.1);
        }

    }

    @include res($res414) {
        height: calc(100vh - 90px);

        .top_log {
            width: 162px;
        }
    }
}

.top_box2 {
    margin-top: 115px;
    padding-bottom: 120px;

    .main {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding-bottom: 71px;

        .right {
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            line-height: 1;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: #333333;

            div {
                width: 25px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            p {
                @include lus_b;
                margin-right: 30px;
            }

            .title {
                @include font_m;
                font-weight: 500;
                padding-right: 50px;
                position: relative;

                &::after {
                    position: absolute;
                    top: 0;
                    right: 26px;
                    display: block;
                    content: "";
                    width: 1px;
                    height: 25px;
                    background: #333333;
                }
            }
        }

        .left {
            text-align: left;
            color: #333333;
            width: 545px;

            .title {
                @include font_m;
                font-weight: 500;
                line-height: 1.8;
                padding-bottom: 20px;
            }

            p {
                font-weight: 500;
                line-height: 1.6;
            }
        }
    }

    .more {
        a {
            max-width: 485px;
            height: 60px;
        }
    }

    @include res($resmd) {
        margin-top: 70px;
        padding-bottom: 80px;

        .main {
            display: block;

            .right {
                writing-mode: horizontal-tb;
                line-height: 1.6;
                display: block;
                text-align: left;

                div {
                    padding-bottom: 24px;
                }

                p {
                    margin-right: 0px;
                    letter-spacing: 1.2px;
                }

                .title {
                    padding-right: 0px;
                    padding-top: 36px;
                    padding-bottom: 80px;

                    &::after {
                        top: 18px;
                        left: 0px;
                        right: auto;
                        right: initial;
                        width: 25px;
                        height: 1px;
                    }
                }
            }

            .left {
                width: 100%;

                .title {
                    padding-bottom: 40px;
                }
            }
        }

        .more {
            a {
                max-width: 280px;
                height: 40px;
            }
        }
    }

    @include res($res414) {
        margin-top: 40px;
        padding-bottom: 60px;

        .main {
            padding-bottom: 43px;

            .right {
                div {
                    padding-bottom: 12px;
                    width: 13px;
                }

                .title {
                    padding-top: 18px;
                    padding-bottom: 40px;

                    &::after {
                        top: 10px;
                        width: 13px;
                    }
                }
            }

            .left {
                .title {
                    padding-bottom: 30px;
                }
            }
        }

        .more {
            a {
                max-width: 280px;
                height: 40px;
            }
        }
    }
}

.top_box3 {
    padding-top: 80px;
    padding-bottom: 120px;
    background-color: #f4f5f0;

    .parts_title1 {
        padding-bottom: 80px;
    }

    .main {
        .wrap {
            overflow: hidden;
        }
    }

    @include res($resmd) {
        padding-top: 50px;
        padding-bottom: 60px;

        .parts_title1 {
            padding-bottom: 40px;
        }
    }
}

.top_works_article {
    height: 420px;
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0px;
    }
    a {
        height: 100%;
        display: flex;

        .img_div {
            width: 50%;
            overflow: hidden;

            span {
                @include trans2(6);
                display: block;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50%;
            }
        }

        .main {
            width: 50%;
            text-align: left;
            display: flex;
            align-items: center;

            .main_wrap {
                max-width: 392px;
                margin-left: auto;
                margin-right: auto;
                padding-left: 20px;
                padding-right: 20px;
                box-sizing: border-box;

                .title {
                    @include font_m;
                    color: #333333;

                    p {
                        text-align: left;
                        padding-bottom: 26px;
                        display: inline-block;
                        position: relative;
                        min-width: 320px;

                        &::after {
                            @include trans2(6);
                            position: absolute;
                            bottom: 22px;
                            left: -154px;
                            content: "";
                            width: calc(100% + 154px);
                            height: 1px;
                            display: block;
                            background-color: #333333;
                        }
                    }

                    h3 {
                        padding-bottom: 22px;
                    }
                }

                .txt {
                    color: #333333;
                    line-height: 1.8;
                    padding-bottom: 35px;
                }

                .more {
                    .parts_more2 {
                        span {
                            @include trans2(6);
                        }
                    }
                }
            }
        }

        &:hover {
            .img_div {
                span {
                    @include trans2(6);
                    transform: scale(1.06);
                }
            }

            .main {
                .main_wrap {
                    .more {
                        .parts_more2 {
                            span {
                                @include trans2(6);
                                right: 18px;
                            }
                        }
                    }
                }
            }
        }
    }

    &:nth-child(2n) {
        a {
            flex-direction: row-reverse;

            .main {
                .main_wrap {
                    .title {
                        text-align: right;

                        p {
                            &::after {
                                position: absolute;
                                bottom: 22px;
                                right: -154px;
                                left: initial;
                                left: auto;
                                content: "";
                                width: calc(100% + 154px);
                                height: 1px;
                                display: block;
                                background-color: #333333;
                            }
                        }
                    }
                }
            }
        }
    }

    @include res($reslg) {
        height: 320px;
    }

    @include res($res1024) {

        a {
            .main {

                .main_wrap {

                    .title {
                        p {
                            min-width: 280px;
                        }
                    }
                }
            }
        }
    }
    @include res($resmd) {
        height: auto;

        a {
            display: block;

            .img_div {
                width: 100%;
                height: 500px;
                margin-bottom: 25px;
            }

            .main {
                width: 100%;
                text-align: left;
                display: block;

                .main_wrap {
                    max-width: 100%;

                    .title {
                        p {
                            padding-bottom: 20px;
                            min-width: initial;

                            &::after {
                                bottom: 16px;
                                left: -40px;
                                width: calc(100% + 40px);
                            }
                        }

                        h3 {
                            padding-bottom: 16px;
                        }
                    }

                    .txt {
                        padding-bottom: 20px;
                    }

                    .more {
                        .parts_more2 {
                            width: 300px;
                        }
                    }
                }
            }
        }

        &:nth-child(2n) {
            a {

                .main {
                    .main_wrap {
                        .title {
                            text-align: left;

                            p {
                                &::after {
                                    position: absolute;
                                    bottom: 16px;
                                    right: initial;
                                    right: auto;
                                    left: -40px;
                                    width: calc(100% + 40px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include res($res600) {
        a {
            .img_div {
                height: 400px;
            }
        }
    }

    @include res($res500) {
        a {
            .img_div {
                height: 300px;
            }
        }
    }

    @include res($res414) {
        a {
            .img_div {
                height: 240px;
            }

            .main {
                .main_wrap {
                    .more {
                        .parts_more2 {
                            width: 240px;
                        }
                    }
                }
            }
        }
    }

    @include res($iphonese) {
        a {
            .img_div {
                height: 220px;
            }
        }
    }
}

.works_more {
    margin-top: 100px;
    a {
        @include font_m;
    }
    @include res($resmd) {
        margin-top: 60px;
        a {
            max-width: initial;
            width: 360px;
        }
    }
    @include res($res414) {
        a {
            max-width: initial;
            width: 280px;
        }
    }
}


.top_box4 {
    padding-top: 80px;
    padding-bottom: 120px;

    .parts_title1 {
        padding-bottom: 80px;
    }

    .main {
        display: flex;
        justify-content: space-between;
        padding-bottom: 80px;

        article {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;

            div {
                max-width: 256px;
                padding-bottom: 20px;
                position: relative;
                min-height: 0%;

                .label {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    display: inline-block;
                    width: 64px;
                    height: 64px;
                    background-color: #333333;
                    color: #fff;
                    @include fontsize(22);
                    @include lus_b;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            p {
                @include font_m;
                color: #333333;
                font-weight: 500;
                padding-left: 38px;
                padding-right: 38px;
                box-sizing: border-box;
                @include fontsize(22);
                line-height: 1.8;
                position: relative;

                &::before {
                    position: absolute;
                    top: 6px;
                    left: 0;
                    content: "";
                    display: inline-block;
                    width: 27px;
                    height: 19px;
                    background-image: url("../img/top/point_icon1.png");
                    background-size: 27px;
                    background-repeat: no-repeat;
                    background-position: 50%;
                }

                &::after {
                    position: absolute;
                    bottom: 10px;
                    right: 0;
                    content: "";
                    display: inline-block;
                    width: 27px;
                    height: 19px;
                    background-image: url("../img/top/point_icon2.png");
                    background-size: 27px;
                    background-repeat: no-repeat;
                    background-position: 50%;
                }
            }
        }
    }

    @include res($reslg) {
        .main {
            article {
                div {
                    max-width: 230px;
                }

                p {
                    padding-left: 22px;
                    padding-right: 22px;
                    @include fontsize(18);

                    &::before {
                        top: 3px;
                        width: 15px;
                        background-size: 15px;
                    }

                    &::after {
                        bottom: 6px;
                        width: 15px;
                        background-size: 15px;
                    }
                }
            }
        }
    }

    @include res($resmd) {
        padding-top: 50px;
        padding-bottom: 60px;

        .parts_title1 {
            padding-bottom: 40px;
        }

        .main {
            display: block;
            padding-bottom: 50px;

            article {
                padding-bottom: 46px;

                &:last-child {
                    padding-bottom: 0px;
                }

                div {
                    max-width: 256px;
                    padding-bottom: 20px;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                p {
                    padding-left: 35px;
                    padding-right: 35px;

                    &::before {
                        top: 5px;
                        width: 27px;
                        background-size: 27px;
                    }

                    &::after {
                        bottom: 7px;
                        width: 27px;
                        background-size: 27px;
                    }
                }
            }
        }
    }
}

.top_box5 {
    padding-top: 80px;
    padding-bottom: 120px;
    background-color: #e8eff5;

    .parts_title1 {
        padding-bottom: 80px;
    }

    .main {
        display: flex;
        padding-bottom: 80px;

        article {
            align-self: flex-start;
            width: 22.8571428571%;
            text-align: left;
            margin-right: 2.85714285714%;
            background-color: #fff;

            &:nth-child(4) {
                margin-right: 0;
            }

            &:nth-child(2n) {
                margin-top: 60px;
            }

            a {
                display: block;

                .img {
                    overflow: hidden;
                    margin-bottom: 12px;
                    position: relative;

                    &::before {
                        z-index: 2;
                        position: absolute;
                        display: block;
                        content: "";
                        width: 0;
                        height: 0;
                        background-color: transparent;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        @include trans2(6);
                    }

                    img {
                        @include trans2(6);
                        width: 100%;
                        height: auto;
                    }
                }

                .content {
                    padding-left: 20px;
                    padding-right: 20px;
                    box-sizing: border-box;
                    color: #333333;
                    @include font_m;

                    header {
                        span {
                            display: block;
                            @include lus_b;
                            color: #999999;
                            padding-bottom: 22px;
                            letter-spacing: 1.3px;
                            position: relative;

                            &:after {
                                position: absolute;
                                bottom: 14px;
                                left: 0;
                                content: "";
                                display: block;
                                width: 20px;
                                height: 1px;
                                background-color: #999999;
                            }
                        }

                        .title {
                            padding-bottom: 6px;
                        }
                    }

                    div {
                        padding-bottom: 15px;
                        line-height: 1.8;
                        border-bottom: 1px solid #999999;
                    }
                }

                &:hover {
                    .img {
                        &::before {
                            @include trans2(6);
                            width: 100%;
                            height: 100%;
                            background: rgba(51, 51, 51, 0.5);
                        }

                        img {
                            @include trans2(6);
                            transform: scale(1.06);
                        }
                    }
                }
            }

            footer {
                padding-top: 15px;
                padding-bottom: 15px;

                a {
                    display: inline-block;
                    margin-left: 20px;
                    box-sizing: border-box;
                    color: #999999;
                    position: relative;

                    &::before {
                        z-index: 2;
                        position: absolute;
                        display: block;
                        content: "";
                        width: 0;
                        height: 1px;
                        background-color: transparent;
                        bottom: -4px;
                        left: 0;
                        @include trans2(6);
                    }

                    &:hover {
                        &::before {
                            background-color: #999999;
                            width: 100%;
                            @include trans2(6);
                        }
                    }
                }
            }
        }
    }

    @include res($resmd) {
        padding-top: 50px;
        padding-bottom: 60px;

        .parts_title1 {
            padding-bottom: 40px;
        }

        .main {
            padding-bottom: 50px;
            flex-wrap: wrap;

            article {
                width: 47.6470588235%;
                margin-right: 4.70588235294%;
                background-color: #fff;

                &:nth-child(2),
                &:nth-child(4) {
                    margin-right: 0;
                }

                &:nth-child(1),
                &:nth-child(2) {
                    margin-bottom: 15px;
                }

                &:nth-child(2n) {
                    margin-top: 0px;
                }

                a {
                    .img {
                        margin-bottom: 10px;
                    }

                    .content {
                        padding-left: 15px;
                        padding-right: 15px;

                        header {
                            span {
                                padding-bottom: 20px;

                                &:after {
                                    width: 13px;
                                    bottom: 14px;
                                }
                            }

                            .title {
                                padding-bottom: 10px;
                            }
                        }

                        div {
                            padding-bottom: 15px;
                        }
                    }
                }

                footer {
                    padding-top: 8px;
                    padding-bottom: 8px;

                    a {
                        margin-left: 15px;

                        &::before {
                            bottom: -2px;
                        }
                    }
                }
            }
        }
    }
    @include res($res414) {

        .main {
            article {
                a {
                    &:hover {
                        .img {
                            &::before {
                                width: 0;
                                height: 0;
                            }
                            img {
                                transform: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
