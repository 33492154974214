@charset "UTF-8";


.br_1024 {
    display: none;
    @include res($res1024) {
        display: block;
    }
    @include res($res414) {
        display: none;
    }
}

/////////////フォント/////////////
@import url('https://fonts.googleapis.com/css?family=Lusitana:400,700');

@mixin font_m {
    font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
    font-weight: 500;
}

@mixin fontLus {
    font-family: 'Lusitana', serif;
    font-weight: bold;
}
