@charset "UTF-8";

.header {
    @include trans2(6);
    position: fixed;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 100;

    .logo {
        margin-left: 64px;
        @include trans2(6);

        a {
            @include trans2(6);
            width: 200px;
            display: inline-block;

            img {
                width: 100%;
                height: auto;
            }

        }
    }

    .main {
        height: 100%;

        nav {
            display: flex;
            height: 100%;

            ul {
                display: flex;
                align-items: center;

                li {
                    a {
                        color: #333333;
                        @include fontsize(16);
                        display: block;
                        margin-right: 32px;
                        position: relative;

                        &:after {
                            @include trans2(3);
                            position: absolute;
                            bottom: -6px;
                            left: 0;
                            content: "";
                            width: 0;
                            height: 3px;
                            background-color: #0f0f65;
                        }

                        &:hover {
                            &:after {
                                width: 100%;
                                @include trans2(3);
                            }
                        }
                    }
                }
            }

            aside {
                height: 100%;

                a {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding-left: 30px;
                    padding-right: 30px;
                    box-sizing: border-box;
                    color: #fff;
                    @include fontsize(16);
                    background-color: #0f0f65;
                    border: 1px solid #0f0f65;
                    @include trans2(6);
                    &:hover {
                        @include trans2(6);
                        background-color: #fff;
                        color: #0f0f65;
                    }
                }
            }
        }
    }

    @include res($res1300) {
        .logo {
            margin-left: 40px;

            a {
                width: 150px;
            }
        }

        .main {
            nav {
                ul {
                    li {
                        a {
                            @include fontsize(15);
                            margin-right: 20px;

                            &:after {
                                height: 2px;
                            }
                        }
                    }
                }

                aside {

                    a {
                        padding-left: 20px;
                        padding-right: 20px;
                        @include fontsize(15);
                    }
                }
            }
        }
    }
    @include res($res1000) {
        height: 50px;
        .logo {
            margin-left: 20px;
        }
        .main {
            display: none;
        }
    }
}

.header_fixed {
    height: 50px;
    @include trans2(6);
    .logo {
        @include trans2(6);
        margin-left: 20px;
        a {
            @include trans2(6);
            width: 160px;
        }
    }
    @include res($res1300) {
        .logo {
            a {
                width: 140px;
            }
        }
    }
    @include res($res1000) {
        height: 40px;
        .logo {
            margin-left: 10px;
            a {
                width: 120px;
            }
        }
    }
}

.sp_menu {
    @include trans2(6);
    z-index: 1000;
    display: none;
    cursor: pointer;
    position: fixed;
    top: 2px;
    right: 20px;
    width: 30px;
    height: 24px;
    background: transparent;
    padding-top: 12px;

    span {
        @include trans2(3);
        display: block;
        position: absolute;
        top: 22px;
        left: 0%;
        width: 100%;
        height: 2px;
        background: #333333;

        &::after,
        &::before {
            @include trans2(3);
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #333333;
        }

        &::before {
            margin-top: -10px;
        }

        &::after {
            margin-top: 10px;
        }
    }
    @include res($res1000) {
        display: inline-block;
    }
}
.sp_menu_close {
    span {
        @include trans2(6);
        background: transparent !important;

        &::after,
        &::before {
            @include trans2(6);
            margin-top: 0 !important;
        }

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(-135deg);
        }
    }
}

.sp_menu_fixed {
    top: 10px;
    right: 10px;
    @include trans2(6);
}


.frv_drawer {
    position: relative;
    z-index: 98;
    @include font_g;
    @include fontsize(14);
    .inner {
        border-left: 1px solid #0f0f65;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        z-index: 99;
        position: fixed;
        right: 0;
        top: 40px;
        height: 100vh;
        overflow-y: scroll;
        background-color: #fff;
        padding-top: 0;
        width: 215px;
        text-align: left;
        transform: translateX(100%);
        transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;

        nav {
            padding-bottom: 100px;

            .frv_drawer_ul1 {
                background-color: #0f0f65;
                padding-top: 10px;
                li {
                    border-bottom: 1px solid #FFF;
                    a {
                        padding: 15px 20px 16px 20px;
                        box-sizing: border-box;
                        display: block;
                        color: #fff;
                        font-weight: 500;
                    }
                }
            }
            aside {
                padding: 10px;
                box-sizing: border-box;
                border-bottom: 1px solid #0f0f65;
                a {
                    background-color: #0f0f65;
                    padding: 15px 10px 16px 10px;
                    display: block;
                    color: #fff;
                    font-weight: 500;
                    border-radius: 4px;
                }
            }
            div {
                padding-top: 15px;
                padding-left: 20px;
                box-sizing: border-box;
                a {
                    display: block;
                    width: 40px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    @include res_min(1001) {
        display: none;
    }
}

.frv_drawer2 {
    .inner {
        transform: translateX(0%) !important;
        transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0.3s;
    }
}

.post-type-archive-news header .main nav ul li:nth-child(1) a::after,
.single-news header .main nav ul li:nth-child(1) a::after,
.tax-news_category header .main nav ul li:nth-child(1) a::after {
    width: 100%;
}

.post-type-archive-blog header .main nav ul li:nth-child(2) a::after,
.single-blog header .main nav ul li:nth-child(2) a::after,
.tax-blog_category header .main nav ul li:nth-child(2) a::after {
    width: 100%;
}

.page-template-page-concept header .main nav ul li:nth-child(3) a::after {
    width: 100%;
}

.page-template-page-flow header .main nav ul li:nth-child(4) a::after {
    width: 100%;
}

.category-works header .main nav ul li:nth-child(5) a::after,
.single-post header .main nav ul li:nth-child(5) a::after {
    width: 100%;
}

.page-template-page-price header .main nav ul li:nth-child(6) a::after {
    width: 100%;
}

.page-template-page-company header .main nav ul li:nth-child(7) a::after {
    width: 100%;
}
