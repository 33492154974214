@charset "UTF-8";

.header_top {
    transform: translateY(-20px);
    opacity: 0;
}

.header_top_anime {
    animation:
        tranY-20 1s 0.8s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}
